import React from 'react';

import Purchase from './models/Purchase'

const PurchaseContext = React.createContext<Purchase | undefined>(undefined);
export { PurchaseContext };

const ColumnContext = React.createContext<{
  colWidth?: number;
  headerHeight?: number;
  rowHeight?: number;
}>({});
export { ColumnContext };