import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ShowTextDialogProps {
  open: boolean;
  title?: string;
  text?: string;
  onClose: (text?: string) => void;
}

const ShowTextDialog: React.FC<ShowTextDialogProps> = (props: ShowTextDialogProps) => {
  const { open, title, text, onClose} = props;
  const classes = useStyles();
  let textarea: HTMLTextAreaElement | null;

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onClose(textarea!.value);
  };

  useEffect(() => {
    if (textarea) {
      textarea!.focus();
    }
  });

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              Изменить
            </Button>
          </Toolbar>
        </AppBar>
        <textarea style={{height: '100%'}} defaultValue={text} ref={ref => { textarea = ref }} />
      </Dialog>
    </div>
  );
};

export default ShowTextDialog;