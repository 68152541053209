import React from 'react';
import Badge from '@material-ui/core/Badge';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

import BaseColumn, { BaseColumnProps } from './BaseColumn';
import Purchase from '../../models/Purchase';
import PurchaseRow from '../../models/PurchaseRow';

const SelectedBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 3,
      top: 10,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }),
)(Badge);

export default class CheckedColumn implements BaseColumn {
  purchase: Purchase;
  onChange: () => void;
  width?: number;

  constructor(purchase: Purchase, onChange: () => void, width: number = 0) {
    this.purchase = purchase;
    this.onChange = onChange;
    this.width = width;
  }
  
  props({ classes, colWidth, headerHeight, rowHeight }: BaseColumnProps) {
    const selectedCount = this.purchase.allRows!.filter(row => row.checked).length || 0;

    const checkRow = (purchase: Purchase, rowId: number, value: boolean) => {
      const curRow = PurchaseRow.getById(rowId);
      curRow.checked = value;
      if (!curRow.hasPrice()) {
        let startCheck = false; 
        purchase.rows!.find(row => {
          if(!startCheck) {
            if (row.id === curRow.id) {
              startCheck = true;
            }
          } else {
            if (!row.hasPrice()) {
              return true;
            }
            row.checked = value;
          }
          return false;
        });
      }
      this.onChange();
    }
    return {
      headerRenderer: () => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight, width: colWidth }}
        align="left"
      >
        <span>
          <SelectedBadge key='notOne' badgeContent={selectedCount < this.purchase.allRows!.length ? selectedCount : 0} color="primary">
            <Checkbox
              color="primary"
              checked={selectedCount>0}
              onChange={(e)=>{
                const value = e.target.checked;
                this.purchase.rows!.forEach(row => {
                  row.checked = value;
                });
                this.onChange();
              }}
            />
          </SelectedBadge>
        </span>
      </TableCell>),
      cellRenderer:({ cellData }: {cellData?: string}) => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: false,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align="left"
      >
        <Checkbox
          checked={PurchaseRow.getById(+cellData!).checked}
          color="primary"
          onChange={(e)=>{
            checkRow(this.purchase, +cellData!, e.target.checked);
          }}
        />
      </TableCell>),
      className: classes.flexContainer,
      dataKey: 'id',
      width: colWidth!
    }
  }
}
