import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import EditedTableCell from '../EditedTableCell';
import SelectedTableCell from '../SelectedTableCell';
import { PurchaseContext } from '../../context';
import Purchase from '../../models/Purchase';

const deliveryOption = '01=РиО (Ленина, 40);02=ЦР АлисА (ул.К.Маркса, 4, Дом Союзов);'+
  '03=ЦРП ОмскКидс (К.Маркса, 8);04=ЦУ "Модники" (Красный Путь, 79а);'+
  '06=ЦР Киви (Герцена 19);14=ПЦР На Дианова (Дианова 20);'+
  '16=Лаптева 3а;17=ПЦР ЧиК (пр-т Космический, 97Б, 1 этаж);'+
  '18=ПЦР "на Рабочих" (ул. Кирова, 5);21=ПЦР на туполева 8 (с 01.02 Бульвар Архитекторов, 14);'+
  '22=ПЦР Амур (21 АМУРСКАЯ, 22Б, 2 ЭТАЖ);24=ПЦР "На Ватутина" (ул.Ватутина, 2, вход со стороны дороги);'+
  '25=Арбат (Нефтяники, пр. Мира 31);26=Апельсин (ТК "Апельсин", ул.Рождественского, 6, корп.1, 3 этаж, бутик 306);'+
  '28=ПЦР "На Конева" ( ул. Конева, 12/3 ТК "Fresco Market");30=Таврическое;'+
  '31=ПЦР Новоомский;32=ПЦР Ясная поляна;'+
  '33=Лузино;34=Любино;'+
  '35=Кормиловка;36=Калачинск;'+
  '37=Московка (ТК "ЮЖАНКА");38=Ключи (пос. Ключи, Харино, Береговой);'+
  '39=ПЦР На Лермонтова;40=Другое';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface TableHeaderDialogProps {
  open: boolean;
  onClose: (purchase?: Purchase) => void;
}

const TableHeaderDialog: React.FC<TableHeaderDialogProps> = (props: TableHeaderDialogProps) => {
  const { open, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const handleChange = (purchase: Purchase) => {
    onClose(purchase);
  };

  return (<Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
    TransitionComponent={Transition}
    keepMounted
  >
    <PurchaseContext.Consumer>
    {(purchase?: Purchase) => {
      const getChanger = function<T extends keyof Purchase>(fieldName: T) {
        return {
          get: () => purchase![fieldName],
          set: (value?: string) => {
            purchase![fieldName] = (value || '') as never;
          }
        };
      };
      return purchase? (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">Заголовок таблицы</DialogTitle>
        <DialogContent>
          <Table size="small" aria-label="Заголовок таблицы">
            <TableBody>
              <EditedTableCell label="Название СП" value={getChanger('title')} />
              <EditedTableCell label="ОРГ%" value={getChanger('orgPercent')} />
              <EditedTableCell label="Минимальная сумма заказа (в рублях)" value={getChanger('minSummaRub')} />
              <EditedTableCell label="Минимальный заказ в других единицах" value={getChanger('minSummaOther')} />
              <SelectedTableCell label="Транспортные расходы" options="1=Транспортные расходы стандартные;2=Транспортные расходы по Омску;3=Без транспортных расходов" value={getChanger('transportCosts')} />
              <EditedTableCell label="Транспортные расходы по другому" value={getChanger('transportCostsAdd')} />
              <SelectedTableCell label="Сбор заказов (рядами / без рядов)" options="1=Без рядов;2=Выкуп рядами" value={getChanger('orderBySeries')} />
              <EditedTableCell label="Дополнительные условия (ряды)" value={getChanger('orderBySeriesAdd')} />
              <SelectedTableCell label="Поэтапность" options="0=Нет;1=Да" value={getChanger('phasing')} />
              <SelectedTableCell label="Пересорт" options="1=Стандартные условия;2=Пересорт невозможен" value={getChanger('resort')} />
              <EditedTableCell label="Дополнительные условия (пересорт)" value={getChanger('resortAdd')} />
              <SelectedTableCell label="Сбор до/после счета" options="1=До счета;2=После счета" value={getChanger('raisingMoneyBeforeAfter')} />
              <EditedTableCell label="Другие варианты (когда сбор)" value={getChanger('raisingMoneyBeforeAfterAdd')} />
              <SelectedTableCell label="Варианты сбора" options="0=на карту Сбербанка;1=на карту Сбербанка и по ведомости в ЦР" value={getChanger('raisingMoney')} />
              <EditedTableCell label="Другие варианты (сбор)" value={getChanger('raisingMoneyAdd')} />
              <SelectedTableCell label="Выдача" options={deliveryOption} value={getChanger('delivery')} />
              <EditedTableCell label="Другие варианты (выдача)" value={getChanger('deliveryAdd')} />
              <SelectedTableCell label="Переброс" options="1=Переброс возможен;2=Перебросов нет;3=Перебросы ограничены" value={getChanger('transfer')} />
              <EditedTableCell label="Дополнительные условия (переброс)" value={getChanger('transferAdd')} />
              <EditedTableCell label="БРАК - дополнительные условия" value={getChanger('defectAdd')} />
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={() => handleChange(purchase!)} color="primary">
            Изменить
          </Button>
        </DialogActions>
      </React.Fragment>)
      : (<React.Fragment />)
    }}
    </PurchaseContext.Consumer>
  </Dialog>);
}

export default TableHeaderDialog;
