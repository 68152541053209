import Purchase from '../models/Purchase';
import PurchaseRow from '../models/PurchaseRow';

import categories from './categories'

async function parse(fileName: string, data: string[][]): Promise<Purchase> {
  let mainHeaderSkipped = false;
  let paramRowNum = 0;
  let tableHeaderSkipped = false;
  const purchase = new Purchase({fileName, rows: []});
  const parsers = [
    {active: false, startTag:'[TABLE_SP_MAIN]', finishTag: '[/TABLE_SP_MAIN]', parseRow: (rowData: string[]) => {
      if (!mainHeaderSkipped) {
        mainHeaderSkipped = true;
      } else {
        const [, title, orgPercent, salePercent, transportPercent, placeOfDelivery, placeOfReceipt, comment, pictureForSocnet, textForSocnet] = rowData;
        Object.assign(purchase, {
          title, orgPercent, salePercent, transportPercent, placeOfDelivery, placeOfReceipt, comment, pictureForSocnet, textForSocnet
        });
      }
    }},
    {active: false, startTag:'[TABLE_SP_PARAM]', finishTag: '[/TABLE_SP_PARAM]', parseRow: (rowData: string[]) => {
      if (paramRowNum === 1) {
        const [, minSumma, transportCosts, orderBySeries, raisingMoneyBeforeAfter, raisingMoney, delivery, defect, resort, transfer, phasing] = rowData;
        const [minSummaRub, minSummaOther] = (minSumma || '').split('|');
        Object.assign(purchase, {
          minSummaRub, minSummaOther, transportCosts, orderBySeries, raisingMoneyBeforeAfter, raisingMoney, delivery, defect, resort, transfer, phasing
        });
      } else if (paramRowNum === 2) {
        const [, minSummaComment, transportCostsAdd, orderBySeriesAdd, raisingMoneyBeforeAfterAdd, raisingMoneyAdd, deliveryAdd, defectAdd, resortAdd, transferAdd] = rowData;
        Object.assign(purchase, {
          minSummaComment, transportCostsAdd, orderBySeriesAdd, raisingMoneyBeforeAfterAdd, raisingMoneyAdd, deliveryAdd, defectAdd, resortAdd, transferAdd
        });
      } else if (paramRowNum === 3) {
        const [, transportCostsComment, orderBySeriesComment, raisingMoneyBeforeAfterComment, raisingMoneyComment, deliveryComment, defectComment, resortComment, transferComment] = rowData;
        Object.assign(purchase, {
          transportCostsComment, orderBySeriesComment, raisingMoneyBeforeAfterComment, raisingMoneyComment, deliveryComment, defectComment, resortComment, transferComment
        });
      }
      paramRowNum++;
    }},
    {active: false, startTag:'[TABLE_SP_CREATE]', finishTag: '[/TABLE_SP_CREATE]', parseRow: (rowData: string[]) => {
      if (!tableHeaderSkipped) {
        tableHeaderSkipped = true;
      } else {
        purchase.rows!.push(new PurchaseRow(rowData));
      }
    }}
  ];
  data.forEach((rowData: string[]) => {
    parsers.forEach(parser => {
      if (rowData[0] === parser.finishTag) {
        parser.active = false;
      }
      if (parser.active) {
        parser.parseRow(rowData);
      }
      if (rowData[0] === parser.startTag) {
        parser.active = true;
      }
    })
  });
  for (let i = 0; i < purchase.rows!.length; i++) {
    const row = purchase.rows![i];
    if (!row.category && row.hasPrice()) {
      const rowCats = await categories.select(row);
      row.category = categories.toString(rowCats);
    }
  }
  purchase.allRows = purchase.rows;
  return purchase;
}

function prepare(purchase: Purchase): (string | undefined)[][] {
  const result: (string | undefined)[][] = [];
  result.push(['[TABLE_SP_MAIN]']);
  result.push(['#', 'Название СП', 'Орг %', 'Скидка %', 'Транспортные расходы %', 'Место доставки', 'Место получения', 'Комментарий', 'Картинка для соцсетей', 'Текст для соцсетей']);
  result.push(['', purchase.title, purchase.orgPercent, purchase.salePercent, purchase.transportPercent, purchase.placeOfDelivery, purchase.placeOfReceipt, purchase.comment, purchase.pictureForSocnet, purchase.textForSocnet]);

  result.push(['[/TABLE_SP_MAIN]']);
  result.push(['[TABLE_SP_PARAM]']);
  result.push(['', 'Минималка', 'Транспортные расходы', 'Ряды', 'Сбор до/после счета', 'Сбор СБ и ЦР', 'Выдача', 'Брак', 'Пересорт', 'Переброс', 'Поэтапность']);
  result.push(['',
    `${parseInt(purchase.minSummaRub || '0') || 0}|${parseInt(purchase.minSummaOther || '0') || 0}`,
    purchase.transportCosts || '1',
    purchase.orderBySeries || '1',
    purchase.raisingMoneyBeforeAfter || '1',
    purchase.raisingMoney || '1',
    purchase.delivery || '1',
    purchase.defect || '1',
    purchase.resort || '1',
    purchase.transfer || '1',
    purchase.phasing || '0'
  ]);
  result.push(['',
    purchase.minSummaComment,
    purchase.transportCostsAdd,
    purchase.orderBySeriesAdd,
    purchase.raisingMoneyBeforeAfterAdd,
    purchase.raisingMoneyAdd,
    purchase.deliveryAdd,
    purchase.defectAdd,
    purchase.resortAdd,
    purchase.transferAdd,
    ''
  ]);
  result.push(['',
    '',
    purchase.transportCostsComment,
    purchase.orderBySeriesComment,
    purchase.raisingMoneyBeforeAfterComment,
    purchase.raisingMoneyComment,
    purchase.deliveryComment,
    purchase.defectComment,
    purchase.resortComment,
    purchase.transferComment,
    ''
  ]);
  result.push(['[/TABLE_SP_PARAM]']);
  result.push(['[TABLE_SP_CREATE]']);
  result.push(['#', 'картинка', 'артикул', 'наименование', 'цена', 'Скидка % (не используется)', 'распродажная цена (не используется)', 'Цвет(между цветами разделитель \'=\')', 'Размер (между размерами разделитель \'=\')', 'пол(м, ж, у)', 'модель', 'материал', 'автор', 'комментарий', 'Код категории', 'Возрастная категория (1 - взрослые, 2 - дети, 3 - новорожденные)', 'Слой одежды (1 - нижнее белье; 2 - 2 слой, 3 - верхняя одежда, 4 - головные уборы)', 'Бренд', 'Информация по размерам']);
  
  purchase.allRows!.forEach((row) => result.push(row.toArray()));
  result.push(['[/TABLE_SP_CREATE]']);
  return result;
}

export default {
  parse,
  prepare,
};
