import React, { useEffect } from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import parser from 'bbcode-to-react';

import { Changer } from './utils';

const useEditStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const TitleTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 14,
      textAlign: 'right',
      width: 230,
    },
  }),
)(TableCell);


export interface SelectedTableCellProps {
  label: string;
  className?: string;
  value: Changer;
  options: string;
}

const SelectedTableCell: React.FC<SelectedTableCellProps> = (props: SelectedTableCellProps) => {
  const { className, options, label, value } = props;
  const [edit, setEdit] = React.useState(false);

  const classes = useEditStyles();

  let input: HTMLSelectElement | undefined;
  const ref = (refInput: HTMLDivElement) => input = refInput?.getElementsByTagName('select')[0];

  const toggleEdit = () => {
    if(edit && input) {
      const newValue = input.value === 'undefined' ? undefined : input.value;
      props.value.set(newValue);
    }
    setEdit(!edit);
  };

  const handleKeyDown = (event: React.KeyboardEvent): any => {
    if (event.key === 'Enter') {
      event.preventDefault();
      toggleEdit();
    }
    if (event.key === 'Esc') {
      event.preventDefault();
      setEdit(false);
    }
  };

  useEffect(() => {
    if (edit) {
      input!.focus();
    }
  });
  function prepareValText(optionInfo: string) {
    const parts = optionInfo.split('=');
    const value = parts[0];
    const text = parts[1];
    return {value, text};
  }
  const optionsDict: {[key:string]: string} = {};
  const optionsInfo = options.split(';').map(prepareValText);
  optionsInfo.forEach((option)=>optionsDict[option.value]=option.text);

  return edit?(
    <TableRow className={className} onClick={toggleEdit}>
      <TableCell colSpan={2}>
        <Select
          native
          className={classes.root}
          label={label}
          defaultValue={value.get()}
          onKeyDown={handleKeyDown}
          onClick={e => e.stopPropagation()}
          onBlur={toggleEdit}
          ref={ref}
        >
          {optionsInfo.map(option=>(<option key={option.value} value={option.value}>{option.text}</option>))}
        </Select>
      </TableCell>
    </TableRow>
  ): (
    <TableRow className={className} onClick={toggleEdit}>
      <TitleTableCell>{label}</TitleTableCell>
      <TableCell>
        { parser.toReact(optionsDict[value.get()!] || '') }
      </TableCell>
    </TableRow>
  );
}

export default SelectedTableCell;
