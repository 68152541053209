import PurchaseRow from '../models/PurchaseRow';
import Purchase from '../models/Purchase';

function testWord(val: string, text: string): boolean {
  return !!~((val || '').toLowerCase().indexOf(text));
}

function includes(row: PurchaseRow, text: string): boolean {
  return !!row.toArray().find(val => testWord(val, text));
}

async function search(purchase: Purchase, text: string) {
  const result = purchase.clone();
  if(!text) {
    result.rows = result.allRows;
  } else {
    result.rows = result.allRows!.filter(row => includes(row, text.toLowerCase()));
  }
  return result;
}

export default {
  search
};
