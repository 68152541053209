/* eslint-disable @typescript-eslint/no-non-null-assertion */
import PurchaseRow from './PurchaseRow'

export default class Purchase {
  title?: string;
  orgPercent?: string;
  salePercent?: string;
  transportPercent?: string;
  placeOfDelivery?: string;
  placeOfReceipt?: string;
  comment?: string;
  pictureForSocnet?: string;
  textForSocnet?: string;

  minSummaRub?: string;
  minSummaOther?: string;
  minSummaComment?: string;
  transportCosts?: string;
  transportCostsAdd?: string;
  transportCostsComment?: string;
  orderBySeries?: string;
  orderBySeriesAdd?: string;
  orderBySeriesComment?: string;
  phasing?: string;
  resort?: string;
  resortAdd?: string;
  resortComment?: string;
  raisingMoneyBeforeAfter?: string;
  raisingMoneyBeforeAfterAdd?: string;
  raisingMoneyBeforeAfterComment?: string;
  raisingMoney?: string;
  raisingMoneyAdd?: string;
  raisingMoneyComment?: string;
  delivery?: string;
  deliveryAdd?: string;
  deliveryComment?: string;
  transfer?: string;
  transferAdd?: string;
  transferComment?: string;
  defect?: string;
  defectAdd?: string;
  defectComment?: string;

  topicId?: number;
  fileName? = '';
  rows?: PurchaseRow[];
  allRows?: PurchaseRow[] = [];

  constructor(options: {
    fileName?: string;
    rows?: PurchaseRow[];
  }) {
    Object.assign(this, options);
  }

  clone(): Purchase {
    const newPurchase = new Purchase({});
    Object.assign(newPurchase, this);
    return newPurchase;
  }
}

