import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';

import BaseColumn, { BaseColumnProps } from './BaseColumn';
import categories from '../../api/categories';
import Category from '../../models/Category';
import PurchaseRow from '../../models/PurchaseRow';

export default class CategoryColumn implements BaseColumn {
  onCategoryChange: (id: number) => void;
  width?: number;
  
  constructor(onCategoryChange: (id: number) => void, width?: number) {
    this.onCategoryChange = onCategoryChange;
    this.width = width;
  }

  props({ classes, colWidth, headerHeight, rowHeight }: BaseColumnProps) {
    return {
      headerRenderer: () => (<TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
          variant="head"
          style={{ height: headerHeight }}
          align="left"
        >
          <span>Категория</span>
        </TableCell>),
      cellRenderer:({ cellData }: {cellData?: string}) => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="body"
        style={{ height: rowHeight }}
        align="left"
        onClick={() => this.onCategoryChange(+cellData!)}
      >
      { categories.getFromRow(PurchaseRow.getById(+cellData!))
          .map((category: Category)=>(<Chip key={category.id} label={category.title} />))
      }
      </TableCell>),
      className: classes.flexContainer,
      dataKey: 'id',
      width: colWidth!
    }
  }
}
