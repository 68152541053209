import React from 'react';
import clsx from 'clsx';
import { withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { AutoSizer, Table, Column } from 'react-virtualized';
import parser from 'bbcode-to-react';

import PurchiseItemDialog from './dialogs/PurchiseItemDialog';
import CategorySelectDialog from './dialogs/CategorySelectDialog';
import BaseColumn from './purchaseTableParts/BaseColumn';
import CheckedColumn from './purchaseTableParts/CheckedColumn';
import ImageColumn from './purchaseTableParts/ImageColumn';
import EditColumn from './purchaseTableParts/EditColumn';
import SelectorColumn from './purchaseTableParts/SelectorColumn';
import TextColumn from './purchaseTableParts/TextColumn';
import MarkColumn from './purchaseTableParts/MarkColumn';
import CategoryColumn from './purchaseTableParts/CategoryColumn';

import categories from '../api/categories';
import Purchase from '../models/Purchase';
import PurchaseRow from '../models/PurchaseRow';
import { PurchaseContext } from '../context';
import { styles } from './purchaseTableParts/styles'
import { Changer } from './utils';

export interface PurchaseTableProps {
  paddingTop: number;
  paddingBottom: number;
  onChange: () => void;
  onEditSelected: () => void;
}

export interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: BaseColumn[];
  headerHeight?: number;
  rowCount: number;
  rowGetter: (row: Row) => PurchaseRow;
  rowHeight?: number;
  purchase: Purchase;
  onChange: () => void;
}

const useStyles = makeStyles(styles);

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps, {scroll: number | undefined}> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  constructor(props: MuiVirtualizedTableProps) {
    super(props);
    this.state = {
      scroll: undefined,
    };
  }

  getRowClassName = ({ index }: Row) => {
    const { classes } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1,
    });
  };

  purchaseWithoutSearch = (): boolean => {
    const { purchase } = this.props;
    return !!purchase.rows && purchase.rows?.length === purchase.allRows?.length;
  }

  onScroll = ({ scrollTop }: { scrollTop: number }) => {
    const { rowHeight } = this.props;
    if (this.purchaseWithoutSearch()) {
      this.setState({
        scroll: scrollTop / (rowHeight || 1)
      });
    }
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
    const fixedWidth: number = columns.filter(column=>column.width).reduce((a,c)=>a+c.width!,0);
    const stretchedCount: number = columns.filter(column=>!column.width).length;
    let scrollTop: number | undefined;
    if (this.purchaseWithoutSearch()) {
      scrollTop = this.state.scroll ? this.state.scroll * (rowHeight || 1) : this.state.scroll;
    }

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: 'inherit',
            }}
            headerHeight={headerHeight!}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
            onScroll={this.onScroll}
            scrollTop={scrollTop}
          >
            {columns.map((column, index) => (<Column
              key={index}
              {...column.props({classes, headerHeight, rowHeight, colWidth: (column.width? column.width: (width - fixedWidth) / stretchedCount)})}
            />))}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const PurchaseTable: React.FC<PurchaseTableProps> = (props: PurchaseTableProps) => {
  const { onChange, onEditSelected, paddingTop, paddingBottom } = props;

  const [showPic, setShowPic] = React.useState(false);
  const [categoryEdit, setCategoryEdit] = React.useState(false);
  const [categoryEditValue, setCategoryEditValue] = React.useState<Changer>({
    get: () => '',
    set: (value?: string) => {}
  });
  const [editOpen, setEditOpen] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState<PurchaseRow | undefined>(undefined);

  async function showEdit(id: number) {
    await setCurrentRow(PurchaseRow.getById(id));
    setEditOpen(true);
  }

  const handleCange = async (purchase: Purchase, row?: PurchaseRow) => {
    if (row) {
      if (currentRow?.category !== row.category) {
        await categories.addCatsToRow(row, purchase!);
      }
      currentRow?.fromArray(row.toArray());
      onChange();
    }
    setEditOpen(false);
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popoverContent, setPopoverContent] = React.useState<string | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, content: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const classes = useStyles();

  return (
    <React.Fragment>
      <CategorySelectDialog open={categoryEdit} value={categoryEditValue} onClose={() => setCategoryEdit(false)}/>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {<Typography>{parser.toReact(popoverContent || '')}</Typography>}
      </Popover>
      <PurchaseContext.Consumer>
        {(purchase?: Purchase) => {
          function handleCategoryEdit(id: number) {
            setCategoryEditValue({
              get: () => PurchaseRow.getById(id).category || '',
              set: async (value?: string) => {
                PurchaseRow.getById(id).category = value;
                await categories.addCatsToRow(PurchaseRow.getById(id), purchase!);
                onChange();
              }
            });
            setCategoryEdit(true);
          }
          return (<Container maxWidth={false} style={{ height: '100vh', width: '100%', paddingTop, paddingBottom }}>
            <PurchiseItemDialog open={editOpen} row={currentRow} onClose={(row?: PurchaseRow) => {handleCange(purchase!, row)}} />
            <Paper style={{ height: '100%', width: '100%' }}>
              <VirtualizedTable
                purchase={purchase!}
                onChange={onChange}
                rowCount={purchase!.rows!.length}
                rowGetter={({ index }) => purchase!.rows![index] || {}}
                rowHeight={showPic ?350 : 48}
                columns={[
                  new CheckedColumn(purchase!, onChange, 60),
                  new EditColumn(purchase!, onEditSelected, showEdit, 60),
                  new ImageColumn(showPic, "img", (newShowPic) => setShowPic(newShowPic), handlePopoverOpen, handlePopoverClose, showPic ? 250 : 60),
                  new TextColumn("title", "Наименование"),
                  new TextColumn("price", "Цена", "right", 70),
                  new SelectorColumn("sex", "=,м=м,ж=ж,у=у,*=*", "Пол", onChange, 60),
                  new SelectorColumn("age", "=,1=взр,2=дети,3=нов", "Возраст", onChange, 100),
                  new SelectorColumn("weatherLayer", "=,1=нижн,2=2 слой,3=верхн,4=головн", "Слой", onChange, 100),
                  new MarkColumn(50),
                  new CategoryColumn(handleCategoryEdit, 200),
                ]}
              />
            </Paper>
          </Container>);
        }}
      </PurchaseContext.Consumer>
    </React.Fragment>
  );
}
export default PurchaseTable;