import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';

import categories from '../api/categories';

import CategorySelectDialog from './dialogs/CategorySelectDialog';

import { Changer } from './utils';

const TitleTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 14,
      textAlign: 'right',
      width: 230,
    },
  }),
)(TableCell);

export interface CategorySelectTableCellProps {
  label: string;
  className?: string;
  value: Changer;
}

const CategorySelectTableCell: React.FC<CategorySelectTableCellProps> = (props: CategorySelectTableCellProps) => {
  const { className, label, value } = props;
  const [edit, setEdit] = React.useState(false);

  const toggleEdit = () => {
    if (!edit) {
      setEdit(true);
    }
  };

  return (
    <TableRow className={className} onClick={toggleEdit}>
      <TitleTableCell>{label}</TitleTableCell>
      <TableCell>
        <CategorySelectDialog open={edit} value={value} onClose={() => setEdit(false)}/>
        { (value.get() || '')
            .split('=')
            .map(id=>categories.get(id))
            .filter(category => category)
            .map(category=>(<Chip key={category.id} label={category.title} />))
        }
      </TableCell>
    </TableRow>
  );
}

export default CategorySelectTableCell;
