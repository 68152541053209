import {parse, unparse, ParseConfig} from 'papaparse';

export default {
  readFromString(csv: string): string[][] {
    const { data } = parse(csv, {
      'delimiter': '\t'
		} as ParseConfig);
    return data;
	},
	convertToString(data: any[]): string {
		return unparse(data, {
      'delimiter': '\t'
    } as ParseConfig);
	}
}