import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import parser from 'bbcode-to-react';

import BaseColumn, { BaseColumnProps } from './BaseColumn';
import { PurchaseRowFields } from '../../models/PurchaseRow';

export default class TextColumn implements BaseColumn {
  fieldName: PurchaseRowFields;
  label: string;
  align: 'right'|'left';
  width?: number;

  constructor(fieldName: PurchaseRowFields, label: string, align?: 'right'|'left', width?: number) {
    this.fieldName = fieldName;
    this.label = label;
    this.align = align || 'left';
    this.width = width;
  }

  props({ classes, colWidth, headerHeight, rowHeight }: BaseColumnProps) {
    return {
      headerRenderer: () => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
        align={this.align}
      >
        <span>{this.label}</span>
      </TableCell>),
      cellRenderer:({ cellData }: {cellData?: string}) => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="body"
        style={{ height: rowHeight }}
        align={this.align}
      >
        <Typography>{parser.toReact(cellData || '')}</Typography>
      </TableCell>),
      className: classes.flexContainer,
      dataKey: this.fieldName,
      width: colWidth!
    }
  }
}
