import { useRef, useEffect } from "react";

export interface Changer {
  get: () => string | undefined;
  set: (value?: string) => void;
}

export function useDebouncedFunction(func: (...args: any[]) => void, delay: number, cleanUp = false) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  // Очистка таймера
  function clearTimer() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }

  // Очищаем таймер при анмаунте компонента, если cleanUp выставлен в true
  // и тем самым отменяем последний запланированный вызов
  useEffect(() => (cleanUp ? clearTimer : undefined), [cleanUp]);

  return (...args: any[]) => {
    clearTimer();
    timeoutRef.current = setTimeout(() => func(...args), delay);
  };
}
