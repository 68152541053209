import React, { useEffect } from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import parser from 'bbcode-to-react';

import { Changer } from './utils';

const useEditStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const TitleTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 14,
      textAlign: 'right',
      width: 230,
    },
  }),
)(TableCell);

export interface EditedTableCellProps {
  label: string;
  className?: string;
  value: Changer;
}

const EditedTableCell: React.FC<EditedTableCellProps> = (props: EditedTableCellProps) => {
  const { label, className, value } = props;
  const [edit, setEdit] = React.useState(false);

  const classes = useEditStyles();

  let input: HTMLInputElement | undefined;
  const ref = (refInput: HTMLDivElement) => input = refInput?.getElementsByTagName('input')[0];

  const toggleEdit = () => {
    if(edit && input) {
      value.set(input.value);
    }
    setEdit(!edit);
  };

  const handleKeyDown = (event: React.KeyboardEvent): any => {
    if (event.key === 'Enter') {
      event.preventDefault();
      toggleEdit();
    }
    if (event.key === 'Esc') {
      event.preventDefault();
      setEdit(false);
    }
  };

  useEffect(() => {
    if (edit) {
      input!.focus();
    }
  });

  return edit?(
    <TableRow className={className} onClick={toggleEdit}>
      <TableCell colSpan={2}>
        <TextField
          className={classes.root}
          label={label}
          defaultValue={value.get()}
          onKeyDown={handleKeyDown}
          onClick={e => e.stopPropagation()}
          onBlur={toggleEdit}
          ref={ref}
        />
      </TableCell>
    </TableRow>
  ): (
    <TableRow className={className} onClick={toggleEdit}>
      <TitleTableCell>{label}</TitleTableCell>
      <TableCell>
        { parser.toReact(value.get() || '') }
      </TableCell>
    </TableRow>
  );
}

export default EditedTableCell;
