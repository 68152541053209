import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Box } from '@material-ui/core';

import UploadButton from '../UploadButton';

const useStyles = makeStyles({
  root: {
    padding: 20,
  },
});

export interface PurchiseLoadDialogProps {
  open: boolean;
  onClose: (file?: File) => void;
}

const FileLoadDialog: React.FC<PurchiseLoadDialogProps> = (props: PurchiseLoadDialogProps) => {
  const classes = useStyles();
  const handleClose = () => {
    props.onClose();
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length <= 0) {
      return;
    }
    props.onClose(files[0]);
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.open}>
      <Box className={classes.root}>
        <DialogTitle id="simple-dialog-title">Файл для загрузки</DialogTitle>
        <UploadButton onChange={handleFileChange}/>
      </Box>
    </Dialog>
  )
}

export default FileLoadDialog;
