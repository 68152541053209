import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';

import BaseColumn, { BaseColumnProps } from './BaseColumn';
import categories from '../../api/categories';
import PurchaseRow, { PurchaseRowFields } from '../../models/PurchaseRow';

export default class SelectorColumn implements BaseColumn {
  fieldName: PurchaseRowFields;
  options: string;
  label: string;
  onChange: () => void;
  width?: number;
  constructor(
    fieldName: PurchaseRowFields,
    options: string,
    label: string,
    onChange: () => void,
    width?: number
  ) {
    this.fieldName = fieldName;
    this.options = options;
    this.label = label;
    this.onChange = onChange;
    this.width = width;
  }

  props({ classes, colWidth, headerHeight, rowHeight }: BaseColumnProps) {
    const optionsInfo = this.options.split(',').map(optionInfo=>({value:optionInfo.split('=')[0], text:optionInfo.split('=')[1]}));
    const optionsDict: {[key:string]: string} = {};
    optionsInfo.forEach(option=>optionsDict[option.value]=option.text);

    return {
      headerRenderer: () => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
        align="left"
      >
        <span>{this.label}</span>
      </TableCell>),
      cellRenderer:({ cellData }: {cellData?: string}) => {
        let input: HTMLSelectElement | undefined;
        const ref = (refInput: HTMLDivElement) => input = refInput?.getElementsByTagName('select')[0];
        const value = {
          get: () => (PurchaseRow.getById(+cellData!) || {})[this.fieldName],
          set: async (value: string) => {
            const row = PurchaseRow.getById(+cellData!);
            row[this.fieldName] = value as never;
            if(!row.categoryChangedByUser) {
              const rowCats = await categories.select(row);
              row.category = categories.toString(rowCats);
            }
            this.onChange();
          }
        };
        const toggleChange = () => {
          value.set(input!.value);
        };
        return (
          <TableCell
            component="div"
            className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
            variant="body"
            style={{ height: rowHeight }}
            align="left"
          >
            <Select
              native
              value={value.get()}
              onChange={toggleChange}
              ref={ref}
            >
              {optionsInfo.map(option=>(<option key={option.value} value={option.value}>{option.text}</option>))}
            </Select>
          </TableCell>
        );
      },
      className: classes.flexContainer,
      dataKey: 'id',
      width: colWidth!
    }
  }
}
