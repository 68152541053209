import React, {useState, useCallback} from 'react';
import 'fontsource-roboto/cyrillic-ext.css';
import Container from '@material-ui/core/Container';

import Copyright from './components/Copyright';
import PurchaseTable from './components/PurchaseTable';
import NavigationPanel from './components/NavigationPanel';
import FileLoadDialog from './components/dialogs/FileLoadDialog';
import ShowTextDialog from './components/dialogs/ShowTextDialog';
import TableHeaderDialog from './components/dialogs/TableHeaderDialog';
import ChangeSelectedDialog from './components/dialogs/ChangeSelectedDialog';
import { useDebouncedFunction } from './components/utils';
import { PurchaseContext } from './context';

import CSV from './api/CSV'
import omskmamaTables from './api/omskmamaTables'
import Purchase from './models/Purchase'
import PurchaseRow from './models/PurchaseRow'
import FileWorker from './api/FileWorker';
import purchaseWorker from './api/purchaseWorker';
import categories from './api/categories';

const App: React.FC<unknown> = () => {
  const [purchase, setPurchase] = useState<Purchase | undefined>(undefined);
  const [showText, setShowText] = useState<string | undefined>(undefined);
  const [showTitle, setShowTitle] = useState<string | undefined>(undefined);
  const [openShow, setOpenShow] = useState(false);
  const [openHeader, setOpenHeader] = useState(false);
  const [openChangeSelected, setOpenChangeSelected] = useState(false);
  const [changeSelectedData] = useState<PurchaseRow>(new PurchaseRow([]));
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const debouncedSearch = useDebouncedFunction(async (text: string) => {
    try {
      const newPurchase = await purchaseWorker.search(purchase!, text);
      setPurchase(newPurchase);
    } catch(e) {
      console.log(e);
    }
  }, 1000);

  const header = useCallback(node => {
    if (node !== null) {
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  }, []);
  const footer = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const loadPurchase = (file?: File) => {
    if (!file) {
      return;
    }
    (async () => {
      const text = await new FileWorker().read(file);
      const data = CSV.readFromString(text);
      const purchase = await omskmamaTables.parse(file.name, data);
      setPurchase(purchase);
    })();
  }

  const savePurchase = () => {
    const fileName = purchase!.fileName!;
    const text = CSV.convertToString(omskmamaTables.prepare(purchase!));
    new FileWorker().upload(fileName, text);
    new FileWorker().save(fileName, text);
  }

  const showPurchase = () => {
    const fileName = purchase!.fileName!;
    const title = purchase!.title;
    const text = CSV.convertToString(omskmamaTables.prepare(purchase!));
    new FileWorker().upload(fileName, text);
    setShowText(text);
    setShowTitle(title);
    setOpenShow(true);
  }

  const closeShow = async (text?: string) => {
    if (text) {
      const data = CSV.readFromString(text);
      const newPurchase = await omskmamaTables.parse(purchase!.fileName!, data);
      setPurchase(newPurchase);
    }
    setOpenShow(false);
  }

  const handleChangePurchase = () => {
    setPurchase(purchase?.clone());
  }

  const handleEditSelected = () => {
    setOpenChangeSelected(true);
  };

  function changeField<T extends keyof PurchaseRow>(to: PurchaseRow, from: PurchaseRow, name: T) {
    if (from[name] || from[name]==='') {
      to[name] = from[name];
    }
  }

  const changeSelected = async (newData?: PurchaseRow) => {
    if(!newData) {
      setOpenChangeSelected(false);
      return;
    }
    const changeCatsRow: Promise<void>[] = [];
    purchase!.rows!.forEach((row: PurchaseRow) => {
      if(row.checked && row.hasPrice()) {
        changeField(row, newData, 'num');
        changeField(row, newData, 'img');
        changeField(row, newData, 'art');
        changeField(row, newData, 'title');
        changeField(row, newData, 'price');
        changeField(row, newData, 'discount');
        changeField(row, newData, 'salePrice');
        changeField(row, newData, 'colors');
        changeField(row, newData, 'sizes');
        changeField(row, newData, 'sex');
        changeField(row, newData, 'model');
        changeField(row, newData, 'material');
        changeField(row, newData, 'author');
        changeField(row, newData, 'comment');
        changeField(row, newData, 'category');
        changeField(row, newData, 'age');
        changeField(row, newData, 'weatherLayer');
        if (newData.sex || newData.age || newData.weatherLayer) {
          changeCatsRow.push(categories.select(row)
            .then(rowCats => {
              row.category = categories.toString(rowCats)
            }));
        }
      }
    });
    if (changeCatsRow.length) {
      await Promise.all(changeCatsRow);
    }
    setOpenChangeSelected(false);
  };

  const changeHeader = (purchase?: Purchase) => {
    if (purchase) {
      setPurchase(purchase);
    }
    setOpenHeader(false);
  }

  return (
    <Container maxWidth={false} style={{backgroundColor: '#f5f5f5'}}>
      <ChangeSelectedDialog open={openChangeSelected} row={changeSelectedData} onClose={changeSelected} />
      <PurchaseContext.Provider value={purchase}>
        <TableHeaderDialog open={openHeader} onClose={changeHeader} />
        <Container maxWidth={false} style={{position: 'fixed', top:0}} ref={header}>
          <NavigationPanel 
            title={purchase?.title}
            onSearch={debouncedSearch}
            onHeaderEdit={()=>{setOpenHeader(true)}}
            savePurchase={savePurchase}
            showPurchase={showPurchase}
          />
        </Container>
        { purchase? <PurchaseTable
          paddingTop={headerHeight}
          paddingBottom={footerHeight}
          onChange={handleChangePurchase}
          onEditSelected={handleEditSelected}
        /> : null }
        <Container maxWidth={false} style={{position: 'fixed', bottom:0}} ref={footer}>
          <Copyright />
        </Container>
        <FileLoadDialog open={!purchase} onClose={loadPurchase} />
        <ShowTextDialog open={openShow} title={showTitle} text={showText} onClose={closeShow} />
      </PurchaseContext.Provider>
    </Container>
  );
}
export default App;
