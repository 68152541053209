import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: '10px',
  },
});

const Copyright: React.FC<{}> = (props: {}) => {
  const classes = useStyles();
  return (
    <Typography className={classes.root} variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://img2.pashkovy.ru/">
        М.Ю. Пашков
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
export default Copyright;