import axios from 'axios';

import CSV from './CSV';
import Category from '../models/Category';
import PurchaseRow from '../models/PurchaseRow';
import Purchase from '../models/Purchase';

let categoryPromise: Promise<Category[]>;
const categoryById: {[key: string]: Category} = {};
const categoriesByKeyWord: {[key: string]: Set<Category>} = {};
let dimensionality = 0;
/*const prepareWords = (s: string)=> s.toLowerCase()
  .replace(/[\s,.\-'"[\]\/\(\)]/g, ' ')
  .replace(/\s+/g, ' ')
  .trim()
  .split(' ')
  .filter(word => !/[^а-яёa-z]/.exec(word))
  .filter(word => word.length > 2);*/
const prepareWords = (s: string)=> s.toLowerCase()
  .replace(/[\s,.\-'"[\]/()*]/g, ' ')
  .replace(/\s+/g, ' ')
  .trim()
  .split(' ')
  .filter(word => !/[^а-яё]/.exec(word))
  .filter(word => word.length > 2 && word !=='описание');

function getAll(): Promise<Category[]> {
  if (!categoryPromise) {
    categoryPromise = axios.get(`${process.env.PUBLIC_URL}/data/categories.csv`)
      .then((response) => {
        const data = CSV.readFromString(response.data);
        const categories: Category[] = [];
        for (let i = 1; i < data.length; i++) {
          const row = data[i];
          if (row.length<4) {
            continue;
          }
          const ids = [];
          for (let j = 1; j <= row[0].length/2; j++) {
            const id = row[0].substr(0, j*2);
            ids.push(id);
          }

          const titles: string[] = row[3].split(' > ');
          let parentCategory: Category | null = null;
          for (let j = 0; j < titles.length; j++) {
            const id = ids[j];
            if (!categoryById[id]) {
              categoryById[id] = {
                id,
                title: titles[j].trim(),
                childrens: [],
                parent: parentCategory,
                selectable: j === titles.length - 1,
              };

              if (parentCategory) {
                parentCategory.childrens.push(categoryById[id]);
              } else {
                categories.push(categoryById[id]);
              }
              if (j === titles.length - 1) {
                categoryById[id].selectable = true;
                let sex;
                if (id.substr(0,2) === '11') {
                  sex = 'ж';
                }
                if (id.substr(0,2) === '20') {
                  sex = 'м';
                }
                categoryById[id].sex = sex;
                categoryById[id].age = row[1];
                categoryById[id].weatherLayer = row[2];

                for (let k = 4; k < row.length; k++) {
                  const words = prepareWords(row[k]);
                  if (words.length > dimensionality) {
                    dimensionality = words.length;
                  }
                  const wordKey = words.join(' ');
                  addToDict(wordKey, [categoryById[id]]);
                }
              }
            }
            parentCategory = categoryById[id];
          }
        }
        return categories;
      });
  }
  return categoryPromise;
}

async function addCatsToRow(changedRow: PurchaseRow, purchase: Purchase) {
  const words = prepareWords(changedRow.title!);
  words.forEach(word => {
    addToDict(word, getFromRow(changedRow));
  });
  changedRow.categoryChangedByUser = true;

  for (let i = 0; i < purchase.allRows!.length; i++) {
    const row = purchase.allRows![i];
    if (!row.category && row.hasPrice()) {
      const rowCats = await select(row);
      row.category = toString(rowCats);
    }
  }
}

function addToDict(word: string, cats: Category[]) {
  if(!categoriesByKeyWord[word]) {
    categoriesByKeyWord[word] = new Set<Category>();
  }
  cats.forEach(cat => categoriesByKeyWord[word].add(cat));
}

function get(id: string): Category {
  return categoryById[id];
}

async function select(row: PurchaseRow): Promise<Category[]> {
  await getAll();
  if(!row.title) {
    return [];
  }
  const words = prepareWords(row.title);
  const maxD = Math.min(words.length, dimensionality);
  const gramms = [];
  for (let d = 1; d <= maxD; d++) {
    for(let j=0; j <= words.length - d; j++) {
      gramms.push(words.slice(j, j+d).join(' '));
    }
  }
  const cats: Category[] = Array.from(new Set(gramms
    .map(gramm=>(categoriesByKeyWord[gramm]||new Set()))
    .reduce((a: Category[], cats: Set<Category>) => a.concat(Array.from(cats)), [])));
  const result: Category[] = cats
    .filter(cat => 
      (row.sex? (row.sex === 'у' && (cat.sex==='м' || cat.sex==='ж')) || row.sex===cat.sex : true) &&
      (row.age? row.age===cat.age : true) &&
      (row.weatherLayer? row.weatherLayer===cat.weatherLayer : true)
    );
  return result;
}

function getFromRow(row: PurchaseRow): Category[] {
  if (!row) {
    return [];
  }
  return (row.category || '')
    .split('=')
    .map((id: string)=>get(id))
    .filter((category: Category) => category)
}

function toString(categories: Category[]): string {
  return categories.map(cat=>cat.id).join('=');
}

export default {
  prepareWords,
  getAll,
  get,
  select,
  getFromRow,
  toString,
  addCatsToRow,
};
