export default class PurchaseRow {
  static currentId = 0;
  static rowById: {[key: number]:PurchaseRow} = {};
  id: number;
  
	num?: string;
	img?: string;
	art?: string;
	title?: string;
	price?: string;
	discount?: string;
	salePrice?: string;
	colors?: string;
	sizes?: string;
	sex?: string;
	model?: string;
	material?: string;
	author?: string;
  comment?: string;
  category?: string;
  age?: string;
  weatherLayer?: string;
  brand?: string;
  sizesInfo?: string;

  checked: boolean;
  categoryChangedByUser?: boolean;
  cloneOf?: number;
  
  constructor(data: any[]) {
    this.id = PurchaseRow.currentId++;
    PurchaseRow.rowById[this.id] = this;
    this.fromArray(data);
    if (this.sex) {
      this.sex = this.sex?.toLowerCase().trim();
      if (!['м','ж','у'].includes(this.sex)) {
        this.sex = '';
      }
    }
    this.checked = false;
  }
  
  static getById(id: number): PurchaseRow {
    return PurchaseRow.rowById[id];
  }

  fromArray(data: any[]) {
    const [
      num,
      img,
      art,
      title,
      price,
      discount,
      salePrice,
      colors,
      sizes,
      sex,
      model,
      material,
      author,
      comment,
      category,
      age,
      weatherLayer,
      brand,
      sizesInfo,
    ] = data;
    Object.assign(this, {
      num,
      img,
      art,
      title,
      price,
      discount,
      salePrice,
      colors,
      sizes,
      sex,
      model,
      material,
      author,
      comment,
      category,
      age,
      weatherLayer,
      brand,
      sizesInfo,
    });
  }
  
  toArray(): any[] {
    return [
      this.num,
      this.img,
      this.art,
      this.title,
      this.price,
      this.discount,
      this.salePrice,
      this.colors,
      this.sizes,
      this.sex,
      this.model,
      this.material,
      this.author,
      this.comment,
      this.category,
      this.age,
      this.weatherLayer,
      this.brand,
      this.sizesInfo,
    ];
  }
  
  clone(): PurchaseRow {
    const result = new (this.constructor as typeof PurchaseRow)(this.toArray()) as this;
    result.categoryChangedByUser = this.categoryChangedByUser;
    result.cloneOf = this.id;
    return result;
  }

  hasPrice(): boolean {
    if (!/^\d+[.,]?\d*$/.test(this.price || '')) {
      return false;
    }
    return !!parseFloat((this.price || '').replace(',', '.'));
  }
}

export type PurchaseRowFields = keyof PurchaseRow;