import React, { ReactElement } from 'react';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';

import BaseColumn, { BaseColumnProps } from './BaseColumn';
import categories from '../../api/categories';
import PurchaseRow from '../../models/PurchaseRow';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 3,
      top: 0,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }),
)(Badge);

export default class MarkColumn implements BaseColumn {
  width?: number;
  constructor(width?: number) {
    this.width = width;
  }

  props({ classes, colWidth, headerHeight, rowHeight }: BaseColumnProps) {
    const showRowMarks = (row: PurchaseRow): ReactElement[] => {
      if (!row) {
        return [];
      }

      const result: ReactElement[] = [];
      if (row.categoryChangedByUser) {
        result.push(<CheckIcon key='changedByUser' className={classes.ok} fontSize="small"/>);
      } else {
        const catsCount = categories.getFromRow(row).length;
        const hasPrice = row.hasPrice();
        if ((hasPrice && catsCount!==1) || (!hasPrice && catsCount!==0) ) {
          result.push((<StyledBadge key='notOne' badgeContent={catsCount} color="primary"><WarningIcon className={classes.warning} fontSize="small"/></StyledBadge>));
        }
      }
      
      return result;
    }

    return {
      headerRenderer: () => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
        align="left"
      >
        <span></span>
      </TableCell>),
      cellRenderer:({ cellData }: {cellData?: string}) => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="body"
        style={{ height: rowHeight }}
        align="left"
      >
      { showRowMarks(PurchaseRow.getById(+cellData!)) }
      </TableCell>),
      className: classes.flexContainer,
      dataKey: 'id',
      width: colWidth!
    }
  }
}

