import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';


import BaseColumn, { BaseColumnProps } from './BaseColumn';
import Purchase from '../../models/Purchase';

export default class EditColumn implements BaseColumn {
  purchase: Purchase;
  onEditSelected: () => void;
  onEdit: (id: number) => void;
  width?: number;

  constructor(purchase: Purchase, onEditSelected: () => void, onEdit: (id: number) => void, width?: number) {
    this.purchase = purchase;
    this.onEditSelected = onEditSelected;
    this.onEdit = onEdit;
    this.width = width;
  }

  props({ classes, colWidth, headerHeight, rowHeight }: BaseColumnProps) {
    const disableSelectedChange = !this.purchase.allRows!.filter(row => row.checked).length;

    return {
      headerRenderer: () => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight, width: colWidth }}
        align="left"
      >
        <span>
          <IconButton disabled={disableSelectedChange} size="small" title="Изменить отмеченные" color="inherit" onClick={this.onEditSelected}>
            <EditIcon />
          </IconButton>
        </span>
      </TableCell>),
      cellRenderer:({ cellData }: {cellData?: string}) => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="body"
        style={{ height: rowHeight }}
        align="left"
      >
        <IconButton aria-label="Редактировать" size="small" onClick={() => this.onEdit(+cellData!)}><EditIcon /></IconButton>
      </TableCell>),
      className: classes.flexContainer,
      dataKey: 'id',
      width: colWidth!
    }
  }
}
