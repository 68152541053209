import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import EditedTableCell from '../EditedTableCell';
import SelectedTableCell from '../SelectedTableCell';
import CategorySelectTableCell from '../CategorySelectTableCell';
import PurchaseRow from '../../models/PurchaseRow';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export interface PurchiseItemDialogProps {
  row?: PurchaseRow;
  open: boolean;
  onClose: (row?: PurchaseRow) => void;
}

const PurchiseItemDialog: React.FC<PurchiseItemDialogProps> = (props: PurchiseItemDialogProps) => {
  const { row, open, onClose } = props;
  const [ currentRow, setCurrentRow ] = React.useState(row?.clone());

  if (row && currentRow?.cloneOf!==row.id) {
    setCurrentRow(row?.clone());
  }
  const classes = useRowStyles();

  if (currentRow) {
    const getChanger = function<T extends keyof PurchaseRow>(fieldName: T) {
      return {
        get: () => currentRow[fieldName],
        set: (value: PurchaseRow[T]) => {
          currentRow[fieldName] = value as never;
          setCurrentRow(currentRow);
        }
      };
    };

    const handleClose = () => {
      onClose();
    };

    const handleChange = () => {
      onClose(currentRow);
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        keepMounted
        TransitionProps={{onExited: ()=>{setCurrentRow(undefined);onClose();}}}
      >
        <DialogTitle id="form-dialog-title">Карточка товара</DialogTitle>
        <DialogContent>
          <Table size="small" aria-label="позиции для закупки">
            <TableBody>
              <EditedTableCell label="Картинка" value={getChanger('img')} />
              <EditedTableCell label="Артикул" value={getChanger('art')} />
              <EditedTableCell label="Наименование" value={getChanger('title')} />
              <EditedTableCell label="Цена" value={getChanger('price')} />
              <EditedTableCell label="Цвет" value={getChanger('colors')} />
              <EditedTableCell label="Размер" value={getChanger('sizes')} />
              <SelectedTableCell label="Пол" options="=;м=м;ж=ж;у=у" value={getChanger('sex')} />
              <SelectedTableCell label="Возраст" options="=;1=взрослые;2=дети;3=новорожденные" value={getChanger('age')} />
              <SelectedTableCell label="Слой одежды" options="=;1=нижнее белье;2=2 слой;3=верхняя одежда;4=головные уборы" value={getChanger('weatherLayer')} />
              <CategorySelectTableCell label="Категория" value={getChanger('category')} />
              <EditedTableCell label="Материал" value={getChanger('material')} />
              <EditedTableCell className={classes.root} label="Комментарий" value={getChanger('comment')} />
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleChange} color="primary">
            Изменить
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        open={false}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
      </Dialog>
    );
  }
}

export default PurchiseItemDialog;
