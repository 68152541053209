import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import Switch from '@material-ui/core/Switch';
import parser from 'bbcode-to-react';

import BaseColumn, { BaseColumnProps } from './BaseColumn';
import { PurchaseRowFields } from '../../models/PurchaseRow';

export default class ImageColumn implements BaseColumn {
  showPic: boolean;
  fieldName: PurchaseRowFields;
  onChange: (showPic: boolean) => void;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>, content: string) => void;
  handlePopoverClose: () => void;
  width?: number;
  constructor(
    showPic: boolean,
    fieldName: PurchaseRowFields,
    onChange: (showPic: boolean) => void,
    handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>, content: string) => void,
    handlePopoverClose: () => void,
    width?: number
  ) {
    this.showPic = showPic;
    this.fieldName = fieldName;
    this.onChange = onChange;
    this.handlePopoverOpen = handlePopoverOpen;
    this.handlePopoverClose = handlePopoverClose;
    this.width = width;
  }

  props({ classes, colWidth, headerHeight, rowHeight }: BaseColumnProps) {
    return {
      headerRenderer: () => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight, width: colWidth }}
        align="left"
      >
        <span style={{ marginLeft: -15 }}>
          <Switch
            title={this.showPic?'Спрятать картинки': 'Показать все картинки'}
            onClick={() => this.onChange(!this.showPic)}
            checked={this.showPic}
            color="default"
          />
        </span>
      </TableCell>),
      cellRenderer:({ cellData }: {cellData?: string}) => (<TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="body"
        style={{ height: rowHeight }}
        align="left"
      >
        {this.showPic? parser.toReact(cellData || '') : (<IconButton aria-label="Картинка" size="small" onMouseEnter={(event) => this.handlePopoverOpen(event, cellData!)} onMouseLeave={this.handlePopoverClose}><Visibility /></IconButton>)}
      </TableCell>),
      className: classes.flexContainer,
      dataKey: this.fieldName,
      width: colWidth!
    }
  }
}
