import React from 'react';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import GetApp from '@material-ui/icons/GetApp';
import Note from '@material-ui/icons/Note';
import ListIcon from '@material-ui/icons/List';
import { useHotkeys } from 'react-hotkeys-hook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
      marginBottom: 10,
      marginRight: 48,
    },
    showPic: {

    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    icon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    inputSearchInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

export interface NavigationPanelProps {
  title?: string;
  onSearch: (text: string) => void;
  onHeaderEdit: () => void;
  savePurchase: () => void;
  showPurchase: () => void;
}

const NavigationPanel: React.FC<NavigationPanelProps> = (props: NavigationPanelProps) => {
  const { title, onSearch, onHeaderEdit, savePurchase, showPurchase } = props;
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  useHotkeys('ctrl+f', (e) => {
    const input = document.getElementById('search_input');
    input?.focus();
    e.preventDefault();
  });

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleChange = (event: { target: { value: string; }; })=> {
    onSearch(event.target.value);
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={savePurchase}>
        <IconButton aria-label="Сохранить" color="inherit">
          <GetApp />
        </IconButton>
        <p>Сохранить</p>
      </MenuItem>
      <MenuItem onClick={showPurchase}>
        <IconButton aria-label="Открыть" color="inherit">
          <Note />
        </IconButton>
        <p>Открыть</p>
      </MenuItem>
      <MenuItem onClick={onHeaderEdit}>
        <IconButton aria-label="Изменить заголовок таблицы" color="inherit">
          <ListIcon />
        </IconButton>
        <p>Изменить заголовок таблицы</p>
      </MenuItem>
    </Menu>
  );
  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {title || ''}
          </Typography>
          <div className={classes.grow}></div>
          <div className={classes.search}>
            <div className={classes.icon}>
              <SearchIcon />
            </div>
            <InputBase
              id="search_input"
              placeholder="Поиск…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputSearchInput,
              }}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'поиск' }}
            />
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton title="Сохранить" color="inherit" onClick={savePurchase}>
              <GetApp />
            </IconButton>
            <IconButton title="Открыть" color="inherit" onClick={showPurchase}>
              <Note />
            </IconButton>
            <IconButton title="Изменить заголовок таблицы" color="inherit" onClick={onHeaderEdit}>
              <ListIcon />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}

export default NavigationPanel;