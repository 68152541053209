import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import categories from '../../api/categories';
import Category from '../../models/Category';
import { Changer } from '../utils';

const useEditStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  three: {
    flexGrow: 1,
    maxWidth: 400,
  },
});

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: '#e8f0fe',
        color: '#1a73e8',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface CategorySelectDialogProps {
  open: boolean;
  value: Changer;
  onClose: () => void;
}

const CategorySelectDialog: React.FC<CategorySelectDialogProps> = (props: CategorySelectDialogProps) => {
  const { open, value, onClose } = props;
  const [categoriesRoot, setCategoriesRoot] = React.useState<Category[] | null>(null);
  const [expanded, setExpanded] = React.useState<string[]|null>(null);
  const [selected, setSelected] = React.useState<string[]|null>(null);

  let propExpanded: string[]|undefined;
  let propSelected: string[]|undefined;
  if (value.get()) {
    const selectedCats = (value.get() || '').split('=').map(id => categories.get(id)).filter(category => category);
    const selectedProps: Set<string> = new Set<string>(selectedCats.map(category => category.id));
    const expandetDefault: Set<string> = new Set<string>();
    selectedCats.forEach(category => {
      let curCat = category;
      while(curCat.parent) {
        curCat = curCat.parent;
        expandetDefault.add(curCat.id);
      }
    });
    propExpanded = Array.from(expandetDefault).sort();
    propSelected = Array.from(selectedProps).sort();
  }

  const classes = useEditStyles();
  const threeItemClasses = useTreeItemStyles();

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    if (!Array.isArray(nodeIds)) {
      nodeIds = [nodeIds];
    }
    const newSelected = nodeIds.map(id => categories.get(id)).filter(category => category.selectable).map(category => category.id);
    setSelected(newSelected);
  };

  const handleClose = async () => {
    await setExpanded(null);
    await setSelected(null);
    await onClose();
  };

  const handleChange = () => {
    value.set(selected!.join('='));
    handleClose();
  };

  useEffect(() => {
    if (!categoriesRoot) {
      categories.getAll()
        .then(newCategoriesRoot => {
          setCategoriesRoot(newCategoriesRoot);
        });
    }
  });

  function showTreeItem(category :Category, parent?: Category) {
    return (<TreeItem
      key={`${parent? parent.id+'/':''}${category.id}`}
      nodeId={category.id}
      label={category.title}
      classes={{
        root: threeItemClasses.root,
        content: threeItemClasses.content,
        expanded: threeItemClasses.expanded,
        selected: threeItemClasses.selected,
        group: threeItemClasses.group,
        label: threeItemClasses.label,
      }}
    >{
      category.childrens.map((child) => showTreeItem(child))
    }</TreeItem>)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle id="form-dialog-title">Выбор категории</DialogTitle>
      <DialogContent>
        <TreeView
          className={classes.three}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded || propExpanded || []}
          selected={selected || propSelected || []}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          multiSelect
        >
          {categoriesRoot?.map((category) => showTreeItem(category))}
        </TreeView>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Отмена
        </Button>
        <Button disabled={!selected} onClick={handleChange} color="primary">
          Изменить
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CategorySelectDialog;
